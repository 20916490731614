
import { defineComponent, ref, watch, onMounted } from "vue";
import { number, object, string } from "yup";
// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiMixin";
import { VueCookieNext } from "vue-cookie-next";
import { ElNotification } from "element-plus";
import { useBus } from "@/bus.ts";
import { DrawerComponent } from "@/assets/ts/components";

export default defineComponent({
  mixins: [apiEndpoint],
  name: "OccupationModal",
  props: {
    data: { type: Object },
    loadData: { type: Function },
  },
  setup(props) {
    const { bus } = useBus();
    const formData = ref({
      id: "",
      updated_by: "",
      created_by: VueCookieNext.getCookie("_user_id"),
      sector_name: "",
      title: "",
    });

    watch(
        () => props.data,
        (newData) => {
          if (newData) {
            formData.value = {
              ...formData.value,
              ...newData,
            };
          }
        }
    );

    const schema = object().shape({
      sector_name: string().required("Sector is required"),
      title: string().required("Occupation is required"),
    });

    const errors = ref({});

    const save = async () => {
      try {
        if (formData.value.id) {
          formData.value.updated_by = VueCookieNext.getCookie("_user_id");
        }
        await schema.validate(formData.value, { abortEarly: false });
        ApiService.post(apiEndpoint.data().VUE_APP_OCCUPATION_STORE, formData.value)
          .then((response) => {
            if (response.data.status == "success") {
              ElNotification({
                dangerouslyUseHTMLString: true,
                message: response.data.data,
                type: "success",
              });
              DrawerComponent.hideAll();
              bus.emit("loadData", true);
            } else {
              ElNotification({
                dangerouslyUseHTMLString: true,
                message: response.data.msg,
                type: "error",
              });
            }
          })
          .catch(({ response }) => {
            console.log(response);
          });

        formData.value = {
          id: "",
          updated_by: "",
          created_by: VueCookieNext.getCookie("_user_id"),
          sector_name: "",
          title: "",
        };
        errors.value = {};
      } catch (validationErrors) {
        errors.value = validationErrors.inner.reduce((acc, error) => {
          acc[error.path] = error.message;
          return acc;
        }, {});
      }
    };

    return { formData, errors, save };
  },
});
